<template>
  <wrinkle-locations-template
    :gender="gender"
    :wrinkles="wrinkles"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import WrinkleLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/wrinkle-locations/WrinkleLocationsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import {
  fieldBuilder,
  convertFieldToLocal,
  createValidator,
  ERROR_MESSAGE
} from '@/modules/questionnaire/mixins/makeStep';

const { fieldWithCustomValidation, field } = fieldBuilder;

const validateWrinkles = wrinkles =>
  Object.keys(wrinkles).some(groupName => wrinkles[groupName] > 0);

const wrinklesValidator = createValidator(validateWrinkles, ERROR_MESSAGE.REQUIRED);

const FIELDS = [
  field('gender'),
  field('foreheadLinesDepth'),
  field('frownLinesDepth'),
  field('crowsFeetDepth'),
  field('bunnyLinesDepth'),
  field('tearThroughDepth'),
  field('nasolabialFoldsDepth'),
  field('verticalLipLinesDepth'),
  field('mouthFrownDepth'),
  field('marionetteLinesDepth'),
  field('chinCreaseDepth'),
  field('neckWrinkleDepth'),

  convertFieldToLocal(fieldWithCustomValidation('wrinkles', [wrinklesValidator]))
];

export default {
  name: 'WrinkleLocations',
  components: {
    WrinkleLocationsTemplate
  },
  mixins: [makeStep(FIELDS)],
  data() {
    return {
      wrinkles: {}
    };
  },
  watch: {
    wrinkles() {
      this.foreheadLinesDepth = this.wrinkles.foreheadLines;
      this.frownLinesDepth = this.wrinkles.frownLines;
      this.crowsFeetDepth = this.wrinkles.crowsFeet;
      this.bunnyLinesDepth = this.wrinkles.bunnyLines;
      this.tearThroughDepth = this.wrinkles.tearTroughs;
      this.nasolabialFoldsDepth = this.wrinkles.nasolabialFolds;
      this.verticalLipLinesDepth = this.wrinkles.verticalLipLines;
      this.mouthFrownDepth = this.wrinkles.mouthFrown;
      this.marionetteLinesDepth = this.wrinkles.marionetteLines;
      this.chinCreaseDepth = this.wrinkles.chinCrease;
      this.neckWrinkleDepth = this.wrinkles.neck;
    }
  },
  created() {
    this.wrinkles.foreheadLines = this.foreheadLinesDepth;
    this.wrinkles.frownLines = this.frownLinesDepth;
    this.wrinkles.crowsFeet = this.crowsFeetDepth;
    this.wrinkles.bunnyLines = this.bunnyLinesDepth;
    this.wrinkles.tearTroughs = this.tearThroughDepth;
    this.wrinkles.nasolabialFolds = this.nasolabialFoldsDepth;
    this.wrinkles.verticalLipLines = this.verticalLipLinesDepth;
    this.wrinkles.mouthFrown = this.mouthFrownDepth;
    this.wrinkles.marionetteLines = this.marionetteLinesDepth;
    this.wrinkles.chinCrease = this.chinCreaseDepth;
    this.wrinkles.neck = this.neckWrinkleDepth;
  }
};
</script>
